<template>
    <CustomerList />
</template>

<script>
import CustomerList from '@/view/components/customer/CustomerList'

export default {
    name: 'Dashboard',
    components: {
        CustomerList
    },
    mounted () {
        this.$store.dispatch('breadcrumb/set', [{ title: 'Dashboard' }])
    }
}
</script>

<style lang="scss" scoped>
</style>
