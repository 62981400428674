<template>
    <b-container>
        <b-table :items="customers"
                 :fields="fields"
                 responsive="sm"
        >
            <template v-slot:cell(select)="{item}">
                <b-link @click="selectCustomer(item)">
                    Auswählen
                </b-link>
            </template>
        </b-table>
    </b-container>
</template>

<script>
import { Customer } from '@/core/services/store/models/customer'

export default {
    name: 'CustomerList',
    data () {
        return {
            fields: [
                'id',
                'company_name',
                'select'
            ]
        }
    },
    computed: {
        customers () {
            return Customer.all()
        }
    },
    methods: {
        selectCustomer (customer) {
            this.$router.push({ name: 'customer-dashboard', params: { customer_id: customer.id } })
        }
    }
}
</script>

<style scoped>

</style>
